const ROUTES = {
  USER_ROUTES: {
    HOME: "/user/",
    MY_ORDERS: "/user/my-orders",
    NEW_ORDER: "/user/new-request",
    NEW_ORDER_OLD: "/user/new-request/old",
    SIMULATE_ORDER: "/user/simulate-request",
    EDIT_ORDER: "/user/edit-order",
    ORDER_PLACED: "/user/order-placed",
    APPOINTMENT_TITLE: "/user/appointment/title",
    APPOINTMENT_INVOICES: "/user/appointment/invoices",
    APPOINTMENT_PRICE: "/user/appointment/price",
    CALL_CENTERS: "/user/call-centers",
    HANDOUTS: "/user/handouts",
    RECLAMATION: "/user/reclamation",
    RECLAMATION_CONSULT: "/user/reclamation-consult",
    RECLAMATION_DETAILS: "/user/reclamation-details/",
    CHANGE_PASSWORD: "/user/change-password",
    DASH_POSH: "/user/dash-posh",
    INSTANT_REWARD: "/user/instant-reward",
    EMPLOYEE: "/user/employee",
    SALES: "/user/sales",
    PERFORMANCE_MANAGEMENT: "/user/performance-management",
    NOZZLE: "/user/nozzle",
    GOALS_AND_COMMISSIONS: "/user/goals-and-commissions",
    PEAK_TIME_HISTORY: "/user/peak-time-history",
    COMMISSION_CALCULATOR: "/user/commission-calculator",
    NOTFOUND: "/user/not-found",
    ROADLOAD: "/user/roadload",
    DISCOUNT: "/user/discount/home",
    PRODUCT: "/user/discount/product",
    NEW_PROGRAMMING: "/user/programação/criar",
    EDIT_PROGRAMMING: "/user/programação/editar/",
  },
  ADMIN_ROUTES: {
    HOME: "/admin/",
    NEW_USER: "/admin/new-user",
    EDIT_USER: "/admin/edit-user",
    LIST_USERS: "/admin/list-users",
    ADD_COMMUNICATION_OLD: "/admin/add-communication/old",
    ADD_COMMUNICATION: "/admin/add-communication",
    ALL_COMMUNICATION: "/admin/communication",
    ADD_IMAGES_LOGIN: "/admin/add-images-login",
    HANDOUTS: "/admin/handouts",
    LIST_INVOICES_IMAGES: "/admin/list-invoices-images",
    CHANGE_PASSWORD: "/admin/change-password",
    PRODUCT_BLOCK: "/admin/product-block",
    RECLAMATION_ANALYSIS: "/admin/reclamation-analysis",
    RECLAMATION_DETAILS_ADM: "/admin/reclamation-details-adm/",
    NOZZLE: "/admin/nozzle",
    DRIVER_AND_CUSTOMER: "/admin/driver-and-customer",
  },
};

export default ROUTES;
