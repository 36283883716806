import {
  StepCurrentIcon,
  StepFinishedIcon,
  StepPendingIcon,
} from "@components/Icons";
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

interface OrderStatusProgressProps {
  step: number;
  statusList?: Array<{
    description: string;
    isMarked?: boolean;
  }>;
}

//NOTE: Progesso com  3 tipos de status (icones): Finalizados -> Atual -> Pendentes
const OrderStatusProgress: React.FC<OrderStatusProgressProps> = ({
  step,
  statusList = [],
}) => {
  const firstText = useRef<HTMLParagraphElement>(null);
  const lastText = useRef<HTMLParagraphElement>(null);
  const [widthFirst, setWidthFirst] = useState(10);
  const [widthLast, setWidthLast] = useState(10);

  const getReference = (index) => {
    return index === 0
      ? firstText
      : index === statusList.length - 1
      ? lastText
      : null;
  };

  const getIcon = (index) => {
    if (step === index) return <StepCurrentIcon />;
    if (step > index) return <StepFinishedIcon />;
    return <StepPendingIcon />;
  };

  function calcPad(width, offset = 10) {
    return `${width / 2 - offset}px`;
  }

  useEffect(() => {
    const textElementFirst = firstText.current;
    const textElementLast = lastText.current;
    setWidthFirst(textElementFirst?.offsetWidth ?? 0);
    setWidthLast(textElementLast?.offsetWidth ?? 0);
  }, []);

  return (
    <Box display={"flex"} flex={1} flexDirection={"column"} width={"100%"}>
      <Grid
        container
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          pl: calcPad(widthFirst),
          pr: calcPad(widthLast),
        }}
      >
        {statusList.map((item, index) => {
          const hasNext = index + 1 < statusList.length;

          return (
            <React.Fragment key={index}>
              <Grid item display={"flex"}>
                {getIcon(index)}
              </Grid>
              {hasNext && (
                <Grid item alignItems="center" flex={1}>
                  <Box border={0.8} borderColor={"shadesOfDark.dark"} />
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
      <Box display={"flex"} justifyContent={"space-between"}>
        {statusList.map((item, index) => (
          <Box key={index}>
            <Typography
              ref={getReference(index)}
              mt={0.8}
              fontSize={12}
              fontWeight={step === index ? 700 : 400}
              noWrap
            >
              {item.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default OrderStatusProgress;
