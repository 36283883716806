import React, { useEffect } from "react";
import { HashRouter, Redirect, Switch } from "react-router-dom";

import RouteGuard from "@components/Routes/RouteGuard";
import {
  permissionRouteMap,
  TypesPermissions,
} from "@components/Routes/TypesPermissions";
import CommunicatedPage from "@pages/Admin/Communications/";
import DriverAndCustomer from "@pages/Admin/DriverAndCustomer";
import ListInvoicesImages from "@pages/Admin/ListInvoicesImages";
import NozzleAdmin from "@pages/Admin/Nozzle";
import ProductBlock from "@pages/Admin/ProductBlock";
import ReclamationConsultAdminMode from "@pages/Admin/ReclamationConsultAdminMode";
import ReclamationDetailsAdminMode from "@pages/Admin/ReclamationsDetailsAdminMode";
import { AppointmentPrice } from "@pages/User/Appointment/AppointmentPrice";
import { ChangePasswordPage } from "@pages/User/ChangePassword";
import DashPoshPage from "@pages/User/DashPosh";
import InstantRewardPage from "@pages/User/DashPosh/InstantRewardPage";
import Employee from "@pages/User/Employee";
import GoalsAndCommissions from "@pages/User/GolsAndComissions";
import NewRequest from "@pages/User/NewRequest";
import NewRequestOld from "@pages/User/NewRequestOld";
import NotFoundPage from "@pages/User/NotFoundPage";
import Nozzle from "@pages/User/Nozzle";
import { NewOrderPage } from "@pages/User/OrderSimulation";
import PerformanceManagement from "@pages/User/PerformanceManagement";
import ComissionCalculator from "@pages/User/PerformanceManagement/Pages/CommissionCalculator";
import PeakTimeHistory from "@pages/User/PerformanceManagement/Pages/PeakTimeHistory";
import Reclamation from "@pages/User/Reclamation";
import ReclamationConsult from "@pages/User/ReclamationConsult";
import ReclamationDetails from "@pages/User/ReclamationDetails";
import RoadLoad from "@pages/User/RoadLoad";
import YourSales from "@pages/User/YourSales";
import { generatePermissionMatcher } from "@utils/index";
import ROUTES from "../../config/routes";
import { HandoutProvider } from "../../context/HandoutContext";
import AddImagesLoginPage from "../../pages/Admin/AddImagesLogin";
import AddUserPage from "../../pages/Admin/AddUser";
import EditUserPage from "../../pages/Admin/EditUser";
import HandoutsAdminPage from "../../pages/Admin/Handouts";
import HomePageAdmin from "../../pages/Admin/Home";
import ListUsersPage from "../../pages/Admin/ListUsers";
import AppointmentInvoices from "../../pages/User/Appointment/AppointmentInvoices";
import AppointmentTitle from "../../pages/User/Appointment/AppointmentTitle";
import CallCenters from "../../pages/User/CallCenters";
import HandoutsUserPage from "../../pages/User/Handouts";
import HomePage from "../../pages/User/Home";
import MyOrders from "../../pages/User/MyOrders";
import OrderPlaced from "../../pages/User/OrderPlaced";
import ProfileRoute from "./ProfileRoute";
import { useAuth } from "@context/auth/AuthContext";
import { TypesProfile } from "@context/auth/enum";
import Discount from "@pages/User/Discount";
import Product from "@pages/User/Product";
import Programming from "@pages/User/RoadLoad/sections/Programming";

const Routes = () => {
  const { permissionSet } = useAuth();

  const permissionSystemModules = permissionSet?.SYSTEM_MODULES;
  const firstPermittedRoute = getFirstPermittedRoute(
    permissionSystemModules,
    permissionSet.ROLES
  );

  useEffect(() => {
    const firstAccessDetected = JSON.parse(
      sessionStorage.getItem("detectedFirstAccess") ?? ""
    );

    if (firstAccessDetected) return;

    if (permissionSystemModules?.length > 0 && firstPermittedRoute) {
      window.location.href = `#${firstPermittedRoute}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HashRouter>
      <HandoutProvider>
        <Switch>
          <ProfileRoute
            path={ROUTES.USER_ROUTES.HOME}
            exact
            component={() => (
              <RouteGuard
                flag="user_sidebar_home_user"
                rules={generatePermissionMatcher(TypesPermissions.HOME)}
              >
                <HomePage />
              </RouteGuard>
            )}
          />

          {/* Funcionarios */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.EMPLOYEE}
            component={() => (
              <RouteGuard
                flag="user_sidebar_employee"
                rules={generatePermissionMatcher(TypesPermissions.EMPLOYEE)}
              >
                <Employee />
              </RouteGuard>
            )}
          />

          {/* Metas e comissoes */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.GOALS_AND_COMMISSIONS}
            component={() => (
              <RouteGuard
                flag="user_sidebar_goal_and_commissions"
                rules={generatePermissionMatcher(
                  TypesPermissions.GOALS_AND_COMMISSIONS
                )}
              >
                <GoalsAndCommissions />
              </RouteGuard>
            )}
          />

          {/* Bico e Produto */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.NOZZLE}
            component={() => (
              <RouteGuard
                flag="user_sidebar_product_nozzle"
                rules={generatePermissionMatcher(TypesPermissions.NOZZLES)}
              >
                <Nozzle />
              </RouteGuard>
            )}
          />

          {/* Suas Vendas */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.SALES}
            component={() => (
              <RouteGuard
                flag="user_sidebar_your_sales"
                rules={generatePermissionMatcher(TypesPermissions.COLETA)}
              >
                <YourSales />
              </RouteGuard>
            )}
          />

          {/* Gestao de desempenho */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.PERFORMANCE_MANAGEMENT}
            component={() => (
              <RouteGuard
                flag="user_sidebar_hide_performance_management"
                rules={generatePermissionMatcher(
                  TypesPermissions.PERFORMANCE_MANAGEMENT
                )}
              >
                <PerformanceManagement />
              </RouteGuard>
            )}
          />

          {/* Carregamento rodoviário */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.ROADLOAD}
            component={() => {
              return (
                <RouteGuard
                  flag="user_sidebar_road_load"
                  rules={generatePermissionMatcher(TypesPermissions.DRIVER)}
                >
                  <RoadLoad />
                </RouteGuard>
              );
            }}
          />

          <ProfileRoute
            path={ROUTES.USER_ROUTES.ORDER_PLACED}
            component={OrderPlaced}
          />

          <ProfileRoute
            path={ROUTES.USER_ROUTES.NEW_ORDER_OLD}
            component={() => (
              <RouteGuard
                rules={generatePermissionMatcher(TypesPermissions.ORDER)}
              >
                <NewRequestOld />
              </RouteGuard>
            )}
          />

          <ProfileRoute
            path={ROUTES.USER_ROUTES.NEW_ORDER}
            component={() => (
              <RouteGuard
                rules={generatePermissionMatcher(TypesPermissions.ORDER)}
              >
                <NewRequest />
              </RouteGuard>
            )}
          />

          <ProfileRoute
            path={ROUTES.USER_ROUTES.MY_ORDERS}
            component={() => (
              <RouteGuard
                rules={generatePermissionMatcher(TypesPermissions.ORDER)}
              >
                <MyOrders />
              </RouteGuard>
            )}
          />

          {/* Consultar Titulos */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.APPOINTMENT_TITLE}
            component={() => (
              <RouteGuard
                rules={generatePermissionMatcher(
                  TypesPermissions.APPOINTMENTS_TITLE
                )}
              >
                <AppointmentTitle />
              </RouteGuard>
            )}
          />

          {/* Consultar Notas Fiscais */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.APPOINTMENT_INVOICES}
            component={() => (
              <RouteGuard
                rules={generatePermissionMatcher(
                  TypesPermissions.APPOINTMENTS_INVOICES
                )}
              >
                <AppointmentInvoices />
              </RouteGuard>
            )}
          />

          {/* Metricas de campanha */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.DASH_POSH}
            component={() => (
              <RouteGuard
                flag="user_sidebar_metrics_and_campaigns"
                rules={generatePermissionMatcher(
                  TypesPermissions.CAMPAIGN_METRICS
                )}
              >
                <DashPoshPage />
              </RouteGuard>
            )}
          />

          {/* Comunicados */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.HANDOUTS}
            component={HandoutsUserPage}
          />

          {/* Simular Carregamento */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.SIMULATE_ORDER}
            component={() => (
              <RouteGuard
                flag="user_sidebar_hide_simulate_loading"
                rules={generatePermissionMatcher(TypesPermissions.SIMULATION)}
              >
                <NewOrderPage />
              </RouteGuard>
            )}
          />

          {/* Desconto */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.DISCOUNT}
            component={() => (
              <RouteGuard
                flag="user_sidebar_hide_discount"
                rules={generatePermissionMatcher(TypesPermissions.DISCOUNT)}
              >
                <Discount />
              </RouteGuard>
            )}
          />

          {/* Preco de Produto */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.APPOINTMENT_PRICE}
            component={() => (
              <RouteGuard
                flag="user_sidebar_price_product_atem"
                rules={generatePermissionMatcher(
                  TypesPermissions.PRODUCT_PRICE
                )}
              >
                <AppointmentPrice />
              </RouteGuard>
            )}
          />

          {/* Produto */}
          <ProfileRoute
            path={ROUTES.USER_ROUTES.PRODUCT}
            component={() => (
              <RouteGuard
                flag="user_sidebar_hide_discount"
                rules={generatePermissionMatcher(TypesPermissions.DISCOUNT)}
              >
                <Product />
              </RouteGuard>
            )}
          />

          <ProfileRoute
            path={ROUTES.USER_ROUTES.CALL_CENTERS}
            component={CallCenters}
          />

          <ProfileRoute
            path={ROUTES.USER_ROUTES.RECLAMATION}
            component={Reclamation}
          />
          <ProfileRoute
            path={ROUTES.USER_ROUTES.RECLAMATION_CONSULT}
            component={ReclamationConsult}
          />
          <ProfileRoute
            path={ROUTES.USER_ROUTES.RECLAMATION_DETAILS}
            component={ReclamationDetails}
          />

          <ProfileRoute
            path={ROUTES.USER_ROUTES.CHANGE_PASSWORD}
            component={ChangePasswordPage}
          />

          <ProfileRoute
            path={`${ROUTES.USER_ROUTES.INSTANT_REWARD}/:companiesId`}
            component={InstantRewardPage}
          />

          <ProfileRoute
            path={ROUTES.USER_ROUTES.NEW_PROGRAMMING}
            component={() => <Programming />}
          />

          <ProfileRoute
            path={`${ROUTES.USER_ROUTES.EDIT_PROGRAMMING}/:programmingId`}
            component={() => <Programming />}
          />

          {/* ---------------- ADMIN ROUTES ---------------- */}

          <ProfileRoute
            exact
            path={ROUTES.ADMIN_ROUTES.HOME}
            component={HomePageAdmin}
          />

          <ProfileRoute
            path={ROUTES.USER_ROUTES.COMMISSION_CALCULATOR}
            component={ComissionCalculator}
          />

          <ProfileRoute
            path={ROUTES.USER_ROUTES.PEAK_TIME_HISTORY}
            component={PeakTimeHistory}
          />

          <ProfileRoute
            path={ROUTES.ADMIN_ROUTES.NEW_USER}
            component={AddUserPage}
          />

          <ProfileRoute
            path={ROUTES.ADMIN_ROUTES.LIST_USERS}
            component={ListUsersPage}
          />

          <ProfileRoute
            path={ROUTES.ADMIN_ROUTES.EDIT_USER}
            component={EditUserPage}
          />

          <ProfileRoute
            path={ROUTES.ADMIN_ROUTES.ALL_COMMUNICATION}
            component={CommunicatedPage}
          />

          <ProfileRoute
            path={ROUTES.ADMIN_ROUTES.ADD_IMAGES_LOGIN}
            component={AddImagesLoginPage}
          />

          <ProfileRoute
            path={ROUTES.ADMIN_ROUTES.HANDOUTS}
            component={HandoutsAdminPage}
          />
          <ProfileRoute
            path={ROUTES.ADMIN_ROUTES.LIST_INVOICES_IMAGES}
            component={ListInvoicesImages}
          />
          <ProfileRoute
            path={ROUTES.ADMIN_ROUTES.CHANGE_PASSWORD}
            component={ChangePasswordPage}
          />
          <ProfileRoute
            path={ROUTES.ADMIN_ROUTES.PRODUCT_BLOCK}
            component={ProductBlock}
          />
          <ProfileRoute
            path={ROUTES.ADMIN_ROUTES.RECLAMATION_ANALYSIS}
            component={ReclamationConsultAdminMode}
          />

          <ProfileRoute
            path={ROUTES.ADMIN_ROUTES.RECLAMATION_DETAILS_ADM}
            component={ReclamationDetailsAdminMode}
          />

          <ProfileRoute
            path={ROUTES.ADMIN_ROUTES.NOZZLE}
            component={NozzleAdmin}
          />
          {/* Motorista vs Cliente */}
          <ProfileRoute
            path={ROUTES.ADMIN_ROUTES.DRIVER_AND_CUSTOMER}
            exact
            component={() => (
              <RouteGuard
                flag="admin_sidebar_driver_and_customer"
                rules={generatePermissionMatcher(
                  TypesPermissions.DRIVER_CUSTOMER
                )}
              >
                <DriverAndCustomer />
              </RouteGuard>
            )}
          />

          <ProfileRoute
            path={ROUTES.USER_ROUTES.NOTFOUND}
            component={() => <NotFoundPage />}
          />

          <Redirect path="*" to={firstPermittedRoute} />
        </Switch>
      </HandoutProvider>
    </HashRouter>
  );
};

const getFirstPermittedRoute = (
  permissions: string[],
  roles: string[]
): string => {
  sessionStorage.setItem("detectedFirstAccess", "true");

  if (
    roles.includes(TypesProfile.ADMIN) ||
    permissions.includes(TypesPermissions.HOME.slice(0, -2))
  ) {
    return permissionRouteMap[TypesPermissions.HOME];
  }

  for (const permission of permissions) {
    const route = permissionRouteMap[`${permission}:*`];
    if (route) {
      return route;
    }
  }

  return ROUTES.USER_ROUTES.NOTFOUND;
};

export default Routes;
