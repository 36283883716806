import React, { useState } from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { Link, Typography, useTheme } from "@material-ui/core";
import { Link as LinkRouter } from "react-router-dom";
import ROUTES from "@config/routes";
import OrderStatusProgress from "@components/ButtonGroupOrderStatusProgress/ButtonGroupOrderStatusProgress";
import ProgrammingSectionOne from "./ProgrammingSectionOne";
import ProgrammingSectionTwo from "./ProgrammingSectionTwo";
import ProgrammingSectionThree from "./ProgrammingSectionThree";
import { ArrowLeftRedIcon } from "@components/Icons";

enum Steps {
  SELECIONAR = 0,
  CARREGAR = 1,
  CONFIRMAR = 2,
}

enum StepDescriptions {
  SELECIONAR = "Selecionar",
  CARREGAR = "Carregar",
  CONFIRMAR = "Confirmar",
}

const Programming = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const [step] = useState(Steps.SELECIONAR);
  //TODO: usar o setStep quando for pra proxima página 0, 1 e 2

  const getStepTitle = (step: Steps): string => {
    const stepTitles = {
      [Steps.SELECIONAR]: "Programar carregamento 1/3",
      [Steps.CARREGAR]: "Programar carregamento 2/3",
      [Steps.CONFIRMAR]: "Programar carregamento 3/3",
    };

    return stepTitles[step] || "";
  };

  const renderStepTitle = (step: Steps) => {
    const title = getStepTitle(step);

    return (
      <Typography
        style={{
          fontSize: "2rem",
          fontWeight: 700,
          color: theme.palette.text.primary,
        }}
      >
        {title}
      </Typography>
    );
  };

  return (
    <div>
      <Box
        sx={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          boxShadow: 1,
        }}
      >
        <Link
          component={LinkRouter}
          to={ROUTES.USER_ROUTES.ROADLOAD}
          underline="none"
        >
          <ArrowLeftRedIcon />
        </Link>
        <Box width={"2rem"} />
        {renderStepTitle(step)}
      </Box>

      <Box
        sx={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
          pt: "4rem",
        }}
      >
        <Grid item xs={12}>
          <OrderStatusProgress
            step={step}
            statusList={[
              { description: StepDescriptions.SELECIONAR },
              { description: StepDescriptions.CARREGAR },
              { description: StepDescriptions.CONFIRMAR },
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          {step === Steps.SELECIONAR && <ProgrammingSectionOne />}

          {step === Steps.CARREGAR && <ProgrammingSectionTwo />}

          {step === Steps.CONFIRMAR && <ProgrammingSectionThree />}
        </Grid>
      </Box>
    </div>
  );
};

export default Programming;
