import FloatingMenuFixedMobile from "@components/FloatingMenuFixedMobile";
import { Grid } from "@material-ui/core";
import React from "react";

const ProgrammingSectionOne: React.FC<{}> = (props) => {
  const handleCloseMenuModal = () => {
    //TODO: Cancelar
    console.log("Cancelar");
  };

  const handleNextMenuModal = () => {
    //TODO: Ao avançar deve atualizar o STEP para 2
    console.log("Avançar");
  };
  return (
    <Grid container xs={12}>
      <Grid item> STEP 1</Grid>

      <FloatingMenuFixedMobile
        firstButtonDescription="Cancelar"
        secondButtonDescription="Avançar"
        handleApprove={handleNextMenuModal}
        handleClose={handleCloseMenuModal}
      />
    </Grid>
  );
};

export default ProgrammingSectionOne;
