import FloatingMenuFixedMobile from "@components/FloatingMenuFixedMobile";
import { Grid } from "@material-ui/core";
import React from "react";

const programmingSectionTwo: React.FC<{}> = (props) => {
  const handleCloseMenuModal = () => {
    //TODO: Voltar
    console.log("Voltar");
  };

  const handleNextMenuModal = () => {
    //TODO: Ao Avançar deve atualizar o STEP para 3
    console.log("Avançar");
  };
  return (
    <Grid container xs={12}>
      <Grid item> STEP 2</Grid>

      <FloatingMenuFixedMobile
        firstButtonDescription="Voltar"
        secondButtonDescription="Avançar"
        handleApprove={handleNextMenuModal}
        handleClose={handleCloseMenuModal}
      />
    </Grid>
  );
};

export default programmingSectionTwo;
