import ROUTES from "@config/routes";

export enum TypesPermissions {
  PORTAL_CLIENTE = "portal_cliente",
  HOME = "portal_cliente:home:*",
  GOALS_AND_COMMISSIONS = "portal_cliente:goals_and_commissions:*",
  PERFORMANCE_MANAGEMENT = "portal_cliente:performance_management:*",
  CAMPAIGN_METRICS = "portal_cliente:campaign_metrics:*",
  ANNOUCEMENTS = "portal_cliente:announcements:*", // announcements = handouts = Comunicados
  CONTACT = "portal_cliente:contact:*",
  CHANGE_PASSWORD = "portal_cliente:change_password:*",
  SIMULATION = "portal_cliente:simulation:*",
  EMPLOYEE = "portal_cliente:employee:*",
  COLETA = "portal_cliente:coleta:*", // COLETA = SALES = "portal_cliente:sales:*",
  NOZZLES = "portal_cliente:nozzles:*",
  PRODUCT_PRICE = "portal_cliente:product_price:*", // Consultar Preco
  DRIVER_CUSTOMER = "portal_cliente:driver_customer:*",
  ORDER = "portal_cliente:orders:*",
  APPOINTMENTS_TITLE = "portal_cliente:appointments_titles:*",
  APPOINTMENTS_INVOICES = "portal_cliente:appointments_invoices:*",
  DRIVER = "portal_cliente:driver:*", // Usuário do tipo Motorista
  DISCOUNT = "portal_cliente:driver:*", // será substituido quando hover integração
  CARRIER = "portal_cliente:carrier:*", // Usuário do tipo Transportadora
  //TODO: definir a permissão do NEW_PROGRAMMING e  EDIT_PROGRAMMING
}

export const permissionRouteMap = {
  [TypesPermissions.HOME]: ROUTES.USER_ROUTES.HOME,
  [TypesPermissions.DRIVER]: ROUTES.USER_ROUTES.ROADLOAD,
  [TypesPermissions.CARRIER]: ROUTES.USER_ROUTES.ROADLOAD,
  [TypesPermissions.EMPLOYEE]: ROUTES.USER_ROUTES.EMPLOYEE,
  [TypesPermissions.GOALS_AND_COMMISSIONS]:
    ROUTES.USER_ROUTES.GOALS_AND_COMMISSIONS,
  [TypesPermissions.NOZZLES]: ROUTES.USER_ROUTES.NOZZLE,
  [TypesPermissions.COLETA]: ROUTES.USER_ROUTES.SALES,
  [TypesPermissions.PERFORMANCE_MANAGEMENT]:
    ROUTES.USER_ROUTES.PERFORMANCE_MANAGEMENT,
  [TypesPermissions.CAMPAIGN_METRICS]: ROUTES.USER_ROUTES.DASH_POSH,
  [TypesPermissions.SIMULATION]: ROUTES.USER_ROUTES.SIMULATE_ORDER,
  [TypesPermissions.PRODUCT_PRICE]: ROUTES.USER_ROUTES.APPOINTMENT_PRICE,

  /* Note: As rotas abaixo fazem parte da home e nao podem sofrer redirecionamento automatico.

  [TypesPermissions.ORDER]: ROUTES.USER_ROUTES.NEW_ORDER,
  [TypesPermissions.APPOINTMENTS_TITLE]: ROUTES.USER_ROUTES.APPOINTMENT_TITLE,
  [TypesPermissions.APPOINTMENTS_INVOICES]: ROUTES.USER_ROUTES.APPOINTMENT_INVOICES,

  */
};

export enum WhatPermission {
  WRITE = "*:WRITE",
}
