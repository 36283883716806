import React from "react";
import { Box, Grid } from "@mui/material";
import { Button } from "@material-ui/core";

interface Props {
  handleApprove: () => void;
  handleClose: () => void;
  secondButtonDescription: string;
  firstButtonDescription: string;
}

const FloatingMenuFixedMobile: React.FC<Props> = ({
  secondButtonDescription,
  firstButtonDescription,
  handleApprove,
  handleClose,
}) => {
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 2,
          left: 0,
          right: 0,
          bgcolor: "background.paper",
          boxShadow: 1,
          zIndex: 2000,
          maxHeight: "50%",
          overflowY: "auto",
          overflowX: "auto",
          p: "2rem",
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={6}>
            <Button
              onClick={handleClose}
              color="primary"
              fullWidth
              style={{ width: "100%", textTransform: "uppercase" }}
            >
              {firstButtonDescription}
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              onClick={handleApprove}
              variant="contained"
              color="primary"
              fullWidth
              style={{ width: "100%", textTransform: "uppercase" }}
            >
              {secondButtonDescription}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FloatingMenuFixedMobile;
