import React from "react";
import { Box, Grid } from "@mui/material";

import { WarnRedIcon } from "@components/Icons";
import Button from "@components/Button";
import Modal from "@components/Modal";

import { TypographyTitle, TypographyCaption } from "../typography";

interface Props {
  children?: React.ReactNode;
  ProgrammingID: string;
  open: boolean;
  onClose: () => void;
  handleCancelProgramming?: () => Promise<void>;
}

const ModalCancelProgramming: React.FC<Props> = ({ children, ...props }) => {
  const { ProgrammingID, open, onClose } = props;

  return (
    <Modal open={open} onClose={onClose} hiddenButton={true}>
      {({ handleClose }) => {
        return (
          <Box
            className={"Box-Cancel-Scheduling"}
            sx={{
              py: { xs: 1, md: 2 },
              px: { xs: 0.3, md: 0.5 },
              width: { xs: "280px", md: "420px" },
            }}
          >
            <Grid container gap={4}>
              <Grid item xs={12}>
                <WarnRedIcon style={{ width: 90, height: 90 }} />
              </Grid>
              <Grid item xs={12}>
                <TypographyTitle>
                  Tem certeza que deseja cancelar a Programação
                  <b>
                    <u style={{ textDecorationColor: "red" }}>
                      {ProgrammingID}
                    </u>
                  </b>
                  ?
                </TypographyTitle>
              </Grid>
              <Grid item xs={12}>
                <TypographyCaption>
                  Ao cancelar, as ordens ficarão disponíveis para serem
                  programadas novamente.
                  {/*TODO: Verificar o texto  se está correto*/}
                </TypographyCaption>
              </Grid>
              <Grid item xs={12}>
                <Grid container gap={3} justifyContent={"center"}>
                  <Grid item>
                    <Button variant="outlined" onClick={handleClose}>
                      Cancelar
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button variant="contained" onClick={() => {}}>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </Modal>
  );
};

export default ModalCancelProgramming;
